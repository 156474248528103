/* eslint-disable */
import React, { useState, useCallback, useEffect } from "react"
import Helmet from "react-helmet"
// import loadable from "@loadable/component"
// import { Router, Match } from "@reach/router"

import IndexSPA from "../components/IndexSPA"

const Index = props => {
  return (
    <>
      <Helmet
        title="家庭健康長・財團法人千禧之愛健康基金會"
        meta={[
          {
            name: `description`,
            content:
              "暑假期間幫家人量測腰圍、幫(外)祖父母量測小腿圍，提醒父母及(外)祖父母可學習從數據來檢視自身健康！",
          },
          {
            property: `og:title`,
            content: "家庭健康長・財團法人千禧之愛健康基金會",
          },
          {
            property: `og:description`,
            content:
              "暑假期間幫家人量測腰圍、幫(外)祖父母量測小腿圍，提醒父母及(外)祖父母可學習從數據來檢視自身健康！",
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content:
              "https://school.1000-love.org.tw/" +
              require("../images/socialimage.jpg"),
          },
        ]}
      ></Helmet>
      <IndexSPA />
    </>
  )
}

// Index.propTypes = {}

export default Index
